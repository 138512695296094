var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},_vm._l((_vm.percetageSequence),function(percent,index){return _c('div',{key:index},[_c('div',{staticClass:"blocks",style:({
                    "width": _vm.width,
                    "height": _vm.height,
                    "background": _vm.heatColors[
                        Object.keys(_vm.heatColors)
                        .filter(key => key <= percent)
                        .reverse()[0]
                    ]
                })},[_c('span',{staticClass:"watched-percentage"},[_vm._v(_vm._s(Math.round(percent))+"% Watched")]),(_vm.events)?_c('div',{staticClass:"event",on:{"mouseover":function($event){_vm.selectedTip = index},"mouseleave":function($event){_vm.selectedTip = null}}},[(_vm.getEvent(index) && _vm.selectedTip == index)?_c('div',{staticClass:"floaty",class:_vm.getEvent(index) ? "down-arrow" : "",style:({"left": _vm.floatyPosition})},[_vm._v(" "+_vm._s(_vm.getEvent(index))+" ")]):_vm._e(),(_vm.getEvent(index))?_c('div',[_c('img',{staticClass:"map-icon smallArrow",attrs:{"src":require("../../src/assets/img/contacts/viewer-interaction.svg")}}),_c('div',{staticClass:"event-line",class:_vm.getEvent(index) ? "centered-event" : ""})]):_vm._e()]):_vm._e()]),(_vm.duration && index % _vm.durationGap == 0)?_c('span',[_vm._v(_vm._s(_vm.duration[index]))]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }