var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-audience",attrs:{"id":"analytics-audience"}},[_c('audience-summary',{attrs:{"date-label":_vm.date_range_label,"filter-options":_vm.filterOptions,"filter-data":_vm.filterData}}),_c('el-row',{staticClass:"m-top-4 video-lists"},[_c('el-col',{attrs:{"span":12,"xs":24}},[_c('top-countries',{attrs:{"date-label":_vm.date_range_label,"top-videos":_vm.filterData.top_countries,"mobile-check":_vm.mobileCheck}})],1),_c('el-col',{attrs:{"span":12,"xs":24}},[_c('top-countries',{attrs:{"progress-color":"#00CAFF","date-label":_vm.date_range_label,"top-videos":_vm.filterData.top_domains,"mobile-check":_vm.mobileCheck,"title-str":"Source URL"}})],1)],1),_c('view-locations',{attrs:{"filter-data":_vm.filterData}}),_c('el-row',{staticClass:"m-top-4"},[_c('el-col',{attrs:{"span":12,"xs":24}},[_c('audience-device',{attrs:{"date-label":_vm.date_range_label,"data-ary":[
                    {title: 'Desktop', percentage: _vm.getPercent(_vm.filterData.views_devices.desktop, _vm.filterData.views_devices.total)},
                    {title: 'Mobile', percentage: _vm.getPercent(_vm.filterData.views_devices.mobile, _vm.filterData.views_devices.total)},
                    {title: 'Tablet', percentage: _vm.getPercent(_vm.filterData.views_devices.tablet, _vm.filterData.views_devices.total)}
                ],"total-views":_vm.filterData.views_devices.total,"limitViews":5}})],1),_c('el-col',{attrs:{"span":12,"xs":24}},[_c('audience-device',{attrs:{"title-str":"Gender","date-label":_vm.date_range_label,"data-ary":[
                    {title: 'Male', percentage: _vm.getPercent(_vm.filterData.gender.male, _vm.filterData.gender.total)},
                    {title: 'Female', percentage: _vm.getPercent(_vm.filterData.gender.female, _vm.filterData.gender.total)},
                ],"total-views":_vm.filterData.gender.total,"limitViews":50}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }